
import { mapState } from 'vuex'
export default {
    layout: 'blank',
    middleware: 'noAuth',
    data: () => ({
        username: '',
        password: '',
        showPassword: false,
        otp: null,
        verifyOTPLoading: false,
    }),
    async fetch() {
        await this.$store.dispatch('auth/checkWhiteLabel')
    },
    computed: {
        ...mapState({
            loading: (state) => state.auth.loading,
            googleAuth: (state) => state.auth.googleAuth,
            wlType: (state) => state.auth.wlType,
        }),
        isInternational() {
            // Just to check if the WhiteLabel Type is international or Lao
            // The decrypt and encrypt functions just to make sure end username can't change the WL easily
            // To do this, in case Checking WhiteLabel API is not working

            if (this.wlType) {
                if (~~this.wlType === 1) return true
                else return false
            }

            const wlt = this.$cookie.get('wlt')
            if (wlt) {
                const type = wlt
                if (~~type === 1) {
                    return true
                }
                return false
            }
            return false
        },
    },
    watch: {
        googleAuth(value) {
            if (value) {
                // set timeout since DOM is rendered late
                setTimeout(() => {
                    const element =
                        document.getElementsByClassName('otp-field-box--0')[0]
                    element.focus()
                }, 500)
            }
        },
    },
    mounted() {
        this.$store.dispatch('auth/verifyGoogle', {})
    },
    methods: {
        async submit(e) {
            if (this.username && this.password) {
                const formData = {
                    username: this.username,
                    password: this.password,
                }
                await this.$store.dispatch('auth/login', formData)
            } else {
                //
            }
        },
        async verifyOTP() {
            this.verifyOTPLoading = true
            const response = await this.$store.dispatch('auth/verifyGoogle', {
                opt_code: this.otp,
            })
            if (!response) {
                const element =
                    document.getElementsByClassName('otp-field-box--0')[0]
                element.focus()
            }
            this.otp = null
            this.verifyOTPLoading = false
        },
    },
}
